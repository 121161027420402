//library import
import  React  from "react";
import  moment  from 'moment';
//custom import
import  { replaceBlankWithDash } from '../../lib/helper'

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

// const reacts = <ReactSelect city={{value:'', label: ' dropdown'}}
//   selected={{value:'', label: ' dropdown'}} />

export const Columns = [
        {
          Header: 'Booking Id',
          Footer: 'Booking Id',
          accessor:  data => replaceBlankWithDash(data.bookingId),
          Filter: SelectColumnFilter,
          disableFilters: true, 
        },
        {
          Header: 'City',
          Footer:'City',
          accessor:  data => replaceBlankWithDash(data.cityName),
          Filter: SelectColumnFilter,
          disableFilters: true,
        },
        {
          Header: 'Date',
          Footer: 'Date',
          accessor:  data => replaceBlankWithDash(data.createdDate),
          disableFilters: true,
        },
        {
          Header: 'Accepted',
          Footer: 'Accepted',
          accessor:  data => replaceBlankWithDash(data.timeStamp.accepted),
          disableFilters: true,
          Cell : ({ value }) => { 
            return (typeof value === "number" ?
                        moment(new Date(value*1000).toISOString()).format("DD MMM YYYY hh:mm:ss A")
                        :  value)
            },
        },
        {
          Header: 'At pickup',
          Footer: 'At pickup',
          accessor:  data => replaceBlankWithDash(data.atPickup),
          disableFilters: true,
          filter: 'includes',
        },
        {
          Header: 'Trip Started',
          Footer: 'Trip Started',
          accessor:  data => replaceBlankWithDash(data.tripStarted),
          disableFilters: true,
        },
        {
          Header: 'Completed',
          Footer: 'Completed',
          accessor:  data => replaceBlankWithDash(data.tripCompleted),
          disableFilters: true,
        },
      ]
  