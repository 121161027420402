//library import
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
//custom import

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography key={"roaaawsss-"} variant="h6">Selected options</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
    ) : null}
    </MuiDialogTitle>
    );
});
const OptionsModal = (props) => {
    return (
        <div>
            <Dialog
                onClose={() => props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={'sm'}
            >
            <DialogTitle id="customized-dialog-title" onClose={() => props.handleClose()}></DialogTitle>
                <DialogContent dividers>
                    <FormGroup>
                        {props.alloptions.map((SelectedOption) => {
                                return <FormControlLabel key={SelectedOption.value}
                                control={
                                        <Checkbox name={SelectedOption.label} color="primary" checked={(props.allShift.findIndex((row) => row === SelectedOption.value) >= 0)} onChange={(e) => props.changeHandlsers(e,SelectedOption)}/>
                                        }
                                label={
                                    SelectedOption.label
                                    }
                                />
                        })}
                    </FormGroup>
                </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()} color="inherit">
                   Ok
                </Button>
            </DialogActions>
            </Dialog>
        </div>
    );
}

export default OptionsModal