import LOGO from "../assets/brand-logo.png";
import LOADER from "../assets/loader.gif";

//APP COLORS
export const THEME_COLOR = "#ffcd0096";
export const LITE_THEME_COLOR = "#f5deb3";
export const BACKGROUND_COLOR ="#f2f2f2";

//API
export const base_api = "http://15.206.153.113:8091/sla/ride/booking?skip=0&limit=20"

// Header Icon
export const SLA_LOGO = LOGO;

//Loader
export const SLA_LOADER = LOADER;
export const TITLE_NAME = "SLA Dashboard";