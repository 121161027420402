//library import
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
//custom import
import { replaceBlankWithDash } from "../../lib/helper";
import DemandModal from "../modal/demandModal";
import OptionsModal from "../modal/optionsModal";
import ReactTable from "../reactTable/reactTable";
import {
  fetchDemand,
  fetchDilogData,
  fecthDownloadData,
} from "../../services/demandDashboard";
import PaginationJs from "../paginationJs/paginationJs";
import ExportModal from "../modal/exportModal";
import DescriptionIcon from "@material-ui/icons/Description";
import queryString from 'query-string';
// import ReactSelect from '../reactSelect/selectSearch';
//css import
import "../reactTable/reactTable.scss";

class DemandDashboard extends Component {
  
  state = {
    tableData: [],
    fcId:null,
    columns: [
      {
        Header: "Sr No",
        accessor: (data) => replaceBlankWithDash(data.name),
        disableFilters: true,
      },
      {
        Header: "From Store",
        accessor: (data) => replaceBlankWithDash(data.name),
        disableFilters: true,
      },
      {
        Header: "Product Name",
        accessor: (data) => replaceBlankWithDash(data.name),
        disableFilters: true,
      },
      {
        Header: "Order Date",
        accessor: (data) => replaceBlankWithDash(data.name),
        disableFilters: true,
      },
      {
        Header: "SKU",
        accessor: (data) => replaceBlankWithDash(data.sku),
        disableFilters: true,
      },
      {
        Header: "Variant Specification",
        accessor: (data) => replaceBlankWithDash(data.variant_spec),
        disableFilters: true,
        filter: "includes",
      },
      {
        Header: "Total Demand",
        accessor: (data) =>
          replaceBlankWithDash(data.total + " (" + data.unit + ")"),
        disableFilters: true,
      },
      {
        Header: "Demand",
        accessor: "sub_total",
        filter: "includes",
        Cell: ({ row }) => (
          <React.Fragment>
            <div className="form-row justify-content-center align-items-center">
              <div className="col p-0">{row.original.sub_total}</div>
              <div className="col text-left p-0">
                <button
                  variant="outlined"
                  onClick={() =>
                    this.handleClickOpen(
                      row.original.productId,
                      row.original.name
                    )
                  }
                  className="btn btn-outline-dark button_More"
                  data-toggle="modal"
                >
                  More information
                </button>
              </div>
            </div>
          </React.Fragment>
        ),
      },
    ],
    list: [],
    dialogData: [],
    dialog: false,
    optionDialog: false,
    dialogloader: true,
    exportdilog: false,
    count: 9,
    skip: 0,
    limit: 20,
    loader: true,
    page: 1,
    totalData: Number,
    age: "",
    dataToShow: 20,
    storeId: "",
    exportCount: 1,
    varient: [{ value: "0", label: "All Varient" }],
    selectedVarient: { value: "0", label: "All Varient" },

    dcData: [{ value: "0", label: "All DC" }],
    selectedDc: { value: "0", label: "All DC" },

    shiftData: [{ value: "0", label: "All Shift" }],
    selectedShift: [{ value: "0", label: "All Shift" }],

    startDate: moment(new Date().setHours(0, 0, 0, 0)).format("X"),
    endDate: moment(moment().endOf("day")).format("X"),

    lastDcId: "",
    exportData: [],
  };
  refs = React.createRef();
  // this.state.setFcStore = this.state.setFcStore.bind(this)
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow ||
      this.state.selectedStoreData !== prevState.selectedStoreData
    ) {
      this.getData();
    } else if (this.state.loader === true) this.setState({ loader: false });
  };

  componentDidMount() {
    this.getData();
    // this.getStoreData();
  }

  changeActivePage = (page) => {
    let state = { ...this.state };
    state.skip = page * state.dataToShow - state.dataToShow;
    // state.limit = page * state.dataToShow;
    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };
    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  //fetch data for More information
  handleClickOpen = async (productID) => {
    const queryParams = [
      productID,
      this.state.skip,
      this.state.limit,
      this.state.selectedDc,
      this.state.selectedShift,
      this.state.startDate,
      this.state.endDate,
      this.state.storeId,
    ];
    await fetchDilogData(...queryParams)
      .then((response) => {
        const dialogposts = response.data.data.table;
        this.setState({
          dialogData: dialogposts === "" ? [] : dialogposts,
          // dialogName:dialogposts.
          dialogloader: false,
        });
      })
      .catch((error) => {
        this.setState({ dialogData: [], dialogloader: false });
        // this.setState({error:true});
      });
    this.setState({ dialog: true });
  };

  //Fetch Data for Store dropdown

  searchOptions = (selectedStoreId) => {
    this.setState({ storeId: selectedStoreId.value }, () => {
      this.getData();
    });
  };

 


  //Fetch Data for dc,shift.
  getData = async () => {

    console.log(this.state.selectedFcStoreId,"BUGHERE")
    const queryParams = [
      this.state.skip,
      this.state.limit,
      this.state.selectedDc,
      this.state.selectedShift,
      this.state.startDate,
      this.state.endDate,
      this.state.storeId,
  
    ];
    await fetchDemand(...queryParams)
      .then((response) => {
        this.setState(
          {
            loader: false,
            tableData: response.data.data,
          },
          () => {
            this.optionhandleClickOpen1();
          }
        );
      })
      .catch((error) => {
        this.setState({
          loader: false,
          list: [],
          dcData: [{ value: "0", label: "All DC" }],
          selectedDc: { value: "0", label: "All DC" },
          shiftData: [{ value: "0", label: "All Shift" }],
          selectedShift: [{ value: "0", label: "All Shift" }],
        });
        // this.setState({error:true});
      });
  };

  //Dc Dropdown option Handler
  changeDcHandler = async (selectedDc) => {
    this.setState(
      {
        selectedDc,
        lastDcId: this.state.selectedDc.value,
        selectedShift: [{ value: "0", label: "All Shift" }],
      },
      () => {
        this.getData();
      }
    );
  };

  //Shift Dropdown option Handler
  changeShiftHandler = async (selectedShift) => {
    if (selectedShift === null) {
      this.setState(
        { selectedShift: [{ value: "0", label: "All Shift" }] },
        () => this.getData()
      );
    } else {
      let makeShift = [];
      for (let i in selectedShift) {
        if (selectedShift[i].value !== "0") {
          const allShiftIndex = this.state.selectedShift.findIndex(
            (itm) => itm.value === "0"
          );
          if (allShiftIndex > -1) {
            makeShift.splice(allShiftIndex, 1);
          }
          makeShift.push(selectedShift[i]);
        } else {
          makeShift = [{ value: "0", label: "All Shift" }];
        }
      }
      this.setState({ selectedShift: makeShift }, () => {
        this.getData();
      });
    }
  };

  // Date piker Handler
  onFilterChnage = (startDate, endDate) => {
    this.setState(
      {
        startDate,
        endDate,
        selectedDc: { value: "0", label: "All DC" },
        selectedShift: [{ value: "0", label: "All Shift" }],
        shiftData: [{ value: "0", label: "All Shift" }],
        exportCount: 1,
      },
      () => {
        this.getData(this.state.skip, this.state.limit);
      }
    );
  };

  handleClose = () => {
    this.setState({ dialog: false });
  };

  handleCloseSelected = () => {
    this.setState({ optionDialog: false });
  };

  handleClosefile = async (count) => {
    const queryParams = [
      this.state.skip,
      this.state.limit,
      this.state.selectedDc,
      this.state.selectedShift,
      this.state.startDate,
      this.state.endDate,
      this.state.storeId,
      this.state.exportCount,
      this.state.fcId
    ]
    this.setState({ exportdilog: false},async()=>{
      await fecthDownloadData(...queryParams).then((responce) => {
        if (
          responce &&
          responce.data &&
          responce.data.data
        ) {
  
          console.log(responce.data.data,"CHECKEXPORT")
          this.setState({
            exportData: responce.data.data,
          });
        }
      });
    });
 
  };

  optionhandleClickOpen = () => {
    let selectedShift = this.state.selectedShift;
    let index = selectedShift.findIndex((x) => x.value === "0");
    if (index >= 0) {
      this.setState({ selectedShift: [...this.state.shiftData] });
    }
    this.setState({ optionDialog: true });
  };

  optionhandleClickOpen1 = () => {
    let selectedShift = this.state.selectedShift;
    let index = selectedShift.findIndex((x) => x.value === "0");
    if (index >= 0) {
      this.setState({ selectedShift: [...this.state.shiftData] });
    }
  };

  exportfile = () => {
    this.setState({ exportdilog: true });
  };

  // shift selector handler
  changeHandlsers = (e, hendleData) => {
    let selectedShift = this.state.selectedShift;
    if (hendleData.value === "0" && e.target.checked) {
      this.setState({ selectedShift: [...this.state.shiftData] }, () => {
        this.getData();
      });
    } else if (hendleData.value === "0" && !e.target.checked) {
      this.setState({ selectedShift: [] }, () => {
        this.getData();
      });
    } else if (e.target.checked) {
      selectedShift.push(hendleData);
      if (selectedShift.length + 1 === this.state.shiftData.length) {
        selectedShift.push({ value: "0", label: "All Shift" });
        this.setState({ selectedShift }, () => {
          this.getData();
        });
      } else {
        let index1 = selectedShift.findIndex((x) => x.value === "0");
        if (index1 >= 0) {
          selectedShift.splice(index1, 1);
        }
        this.setState({ selectedShift }, () => {
          this.getData();
        });
      }
    } else {
      let index = selectedShift.findIndex((x) => x.value === hendleData.value);
      if (index >= 0) {
        selectedShift.splice(index, 1);
      }
      let index1 = selectedShift.findIndex((x) => x.value === "0");
      if (index1 >= 0) {
        selectedShift.splice(index1, 1);
      }
      this.setState({ selectedShift: selectedShift }, () => {
        this.getData();
      });
    }
  };

  handleFC = async(id)=>{
    console.log(id,"FCGETCHECk")

    this.setState({
      fcId:id
    },async()=>{
      const queryParams = [
        this.state.skip,
        this.state.limit,
        this.state.selectedDc,
        this.state.selectedShift,
        this.state.startDate,
        this.state.endDate,
        this.state.storeId,
       id
      ];
      await fetchDemand(...queryParams)
        .then((response) => {
          this.setState(
            {
              loader: false,
              tableData: response.data.data,
            },
            () => {
              this.optionhandleClickOpen1();
            }
          );
        })
    })

  
  }
  render() {
    // console.log(this.props.location.hash,"IDS")

    const AllShiftIdArray = [];
    for (let index = 0; index < this.state.selectedShift.length; index++) {
      AllShiftIdArray.push(this.state.selectedShift[index].value);
    }
    // const storeSelect = <ReactSelect change={this.searchOptions}/>

    const dataPrint = (
      <ReactToPrint content={() => this.refs}>
        <PrintContextConsumer>
          {({ handlePrint }) => (
            <PrintIcon
              onClick={handlePrint}
              className="file_exports"
            ></PrintIcon>
          )}
        </PrintContextConsumer>
      </ReactToPrint>
    );
    return (
      <>
        <style>
          {`
          .demandOption > div.MuiOutlinedInput-input{
            width:200px;
            padding: 10px 20px!important; 
          }
          .selectors{
            width: 240px;        
            height: 50px;
          }
          .reactSelect_contaion{
            max-width:188px;
          }
        `}
        </style>

        <div style={{ padding: "0px 20px" }}>
          <div className="tableHeaderContent">
            <div className="tableLableDiv">
              <div className="tableIcon" style={{ top: "12.5rem" }}>
                <DescriptionIcon></DescriptionIcon>
              </div>
            </div>
          </div>

          <div className="dataDisplay">
            {this.state.list ? (
              <ReactTable
               handleFC = {this.handleFC}
                demandData={this.state.tableData}
                columns={this.state.columns}
                data={this.state.list}
                varient={this.state.varient}
                selectedVarient={this.state.selectedVarient}
                dcData={this.state.dcData}
                shiftData={this.state.shiftData}
                dcDataSelected={this.state.selectedDc}
                shiftDataSelected={this.state.selectedShift}
                dcChangeDcHandler={this.changeDcHandler}
                changeShiftHandler={this.changeShiftHandler}
                columCounts={this.state.count}
                onFilterChnage={this.onFilterChnage}
                name="Demand Dashboard"
                // storeSelect={storeSelect}
                loaders={this.state.loader}
                handleClickOpen={this.optionhandleClickOpen}
                dataToShow={this.state.dataToShow}
                reff={(el) => {
                  this.refs = el;
                }}
                exportfile={this.exportfile}
                dataPrint={dataPrint}
                classes="specific_table_custom table_custom"
              />
            ) : null}
            {/* --- pagination --- */}
            <PaginationJs
              changeActivePage={this.changeActivePage}
              activePage={this.state.page}
              totalData={this.state.totalData}
              dataToShowHandler={this.dataToShowHandler}
              dataToShow={this.state.dataToShow}
            />
          </div>
          {/* </div> */}
        </div>
        <DemandModal
          alldialog={this.state.dialogData}
          loaders={this.state.dialogloader}
          openState={this.state.dialog}
          handleClose={this.handleClose}
        />

        <OptionsModal
          alloptions={this.state.shiftData}
          allShift={AllShiftIdArray}
          changeHandlsers={this.changeHandlsers}
          open={this.state.optionDialog}
          handleClose={this.handleCloseSelected}
        />

        <ExportModal
          openState={this.state.exportdilog}
          handleClose={() => this.handleClosefile(1)}
          data={this.state.tableData || []}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    abc: state,
  };
};

export default connect(mapStateToProps)(DemandDashboard);
