// eslint-disable-next-line no-unused-vars
import * as actionTypes from "./actions/actionTypes";

export const initialState = {
  colume: [],
  data: [],
  selectOp: [],
  selectedValue:'',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COLUNME:
      
      return {
        ...state
      };
    
    case actionTypes.GET_DATA:
        return {
            ...state
          };
    case actionTypes.SET_SELECT:
        console.log("action.data",action.data)
    return {
        ...state,
        ...action.data
        };
    default:
      return state;
  }
};

export default reducer;
