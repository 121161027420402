//library import
import React, { Component } from 'react'
//custom import
import { Columns } from './ridesColumn';
import { fetchRides } from '../../services/rides';
import  ReactTable from '../reactTable/reactTable';
import  '../reactTable/reactTable.scss';
import PaginationJs from '../paginationJs/paginationJs';

class Rides extends Component {

  state={
    list:[],
    columns:[...Columns],
    count:7,
    skip:0,
    limit:20,
    loader:true,
    page:1,
    totalData: Number,
    age: '',
    dataToShow:20,
  }

  handleChange = (event) => {
   this.setState({age:event.target.value});
  };
  
  componentDidUpdate = (prevProps, prevState) => {
    //old params and new params are diff than get new data
    if ((this.state.page !== prevState.page)
      || (this.state.dataToShow !== prevState.dataToShow) || (this.state.limit !== prevState.limit))
      //  || (JSON.stringify(this.state.selectedCity) !== JSON.stringify(prevState.selectedCity))
      {
      this.getData(
        this.state.skip,
        this.state.limit,
        //  this.state.selectedCity.value
      );
    }
    //old params and new params are same ...close loader
    else if (this.state.loader === true)
      this.setState({ loader: false })
  }

  componentDidMount(){
        this.getData(
          this.state.skip,
          this.state.limit,
        );
  }

  changeActivePage = (page) => {
    let state = { ...this.state };
    state.skip = page * state.dataToShow - state.dataToShow;
    // state.limit = page * state.dataToShow;
    state.page = page;
    state.loader = true;
    this.setState(state)
  }

  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };
    stateCopy.page = 1;
    stateCopy.skip = 0
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy)
  }
  
  getData = async (skip,limit) =>{
    await fetchRides(skip,limit)
      .then( response => {
        const posts = response.data.data;
          this.setState(
            {
              list: posts === '' ? [] : posts,loader:false,
              totalData:response.data.pen_count,
            });
      })
      .catch(error=>{
        this.setState({loader:false});
          // this.setState({error:true});
      });
  }

  onFilterChnage = (startDate,endDate) => {
    this.setState({startDate,endDate},()=>{
      this.getData(this.state.skip,this.state.limit);
   })
  }
  render() {
    const data = this.state.list ? 
      <ReactTable 
        columns={this.state.columns} 
        data={this.state.list} 
        columCounts={this.state.count} 
        name="Rides" 
        loaders={this.state.loader}
        onFilterChnage = {this.onFilterChnage}
        dataToShow={this.state.dataToShow}
        classes="table_custom"/> 
        : null
    return (
        <>
        {/* <div className="Options"></div> */}
        <div className="dataDisplay">
          { data }
          {/* --- pagination --- */ }
            {/*<div className="paginationWrapper" style={{padding:"0px 15px"}}>*/}
              <PaginationJs
              changeActivePage={this.changeActivePage}
              activePage={this.state.page}
              totalData={this.state.totalData}
              dataToShowHandler={this.dataToShowHandler}
              dataToShow={this.state.dataToShow}
              />
            {/*</div>*/}
          </div>
        </>
    )
  }
}
export default Rides
