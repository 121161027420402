//library import
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import  moment  from 'moment';
//custom import
import Loader from '../loader/Loader';
import NotExist from '../reactTable/notExist';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CustomizedDialogs = (props) => {

const { alldialog, loaders } = props;
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            { alldialog.length !== 0 ? 
                alldialog.map((row, index) => {
                return ( 
                    index===0 ?
                        <Typography key={"roaaawsss-"+index} variant="h6">Demands Of {row.name}</Typography>:null
                )
            }):<Typography variant="h6">Demands</Typography>}
            
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

    return (
        <>
        <style>
        {`
            .demandModal thead tr th{
                position: inherit;
                background-color: white;
            }
      `}
      </style>
        <div>
            <Dialog
            onClose={() => props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.openState}
            fullWidth={true}
            maxWidth={'sm'}
            >
            <DialogTitle id="customized-dialog-title" onClose={() => props.handleClose()}>
            </DialogTitle>
            <DialogContent dividers>
                    <table className="table demandModal">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Cutofftime/ outbound time</th>
                                <th>Units</th>
                                <th>Shifts</th>
                                </tr>
                        </thead>
                        <tbody>
                            {loaders ? 
                                <tr style={{height:"0px"}}>
                                    <td colSpan="3" className="" style={{verticalAlign: "inherit"}}>
                                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {
                                            //conditional rendering of loader
                                            <Loader/>
                                        }
                                        </div>
                                    </td>
                                </tr>: (alldialog.length !== 0) ? 
                                alldialog.map((row, index) => {
                                    return ( 
                                    <tr key={"roaaawsss-"+index} >
                                        <td>{row.date}</td>
                                        <td>{
                                            (typeof row.startDateTime === "number" ?
                                            moment(new Date((row.startDateTime + (new Date().getTimezoneOffset() * 60))*1000).toISOString()).format("DD MMM YYYY hh:mm:ss A")
                                            :  row.startDateTime)
                                        }</td>
                                        <td>{row.total+" ("+row.unit+")"}</td>
                                        <td>{row.Name}</td>
                                    </tr>
                                    )
                                }) : <NotExist count="4" height="100px"/>}
                        </tbody>
                    </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()} color="inherit">
                   Cancel
                </Button>
            </DialogActions>
            </Dialog>
        </div>
        </>
    );
}

export default CustomizedDialogs