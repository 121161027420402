import axios from 'axios'
import { api,storeApi } from '../config/app'
// import { getCookie } from './session'
import { getNewAccessToken } from './refreshToken'
import ls from 'local-storage'

const getAccessToken = () => {
    const accessToken = ls.get('token')
    const refreshToken = ls.get('refreshToken');
    return getNewAccessToken(refreshToken, accessToken)
        .then((data) => {
        ls.remove('token');
        ls.set('token', data.data.accessToken);
        // return getForReport.call(this, url)
        window.location.reload()
    })
    .catch((err) => {
        console.log('Your accessToken', err)
    })
}
axios.interceptors.response.use((response)=>{
    return response
    }, async (error)=>{
        console.log('auth error', error);
        if(!error.response){
        return Promise.reject(error)
    }
        if(error.response.status === 401){
                window.location.href = '/login'
            return
        }
        if(error.response.status === 406){
            getAccessToken()
        }
        return Promise.reject(error)
    })

export const get = async( endpoint ) => {
    let urls  = api + endpoint;
    return await axios.get(urls,{ 
        headers: {
            Authorization: ls.get("token"),
            "Content-Type": "application/json"
        }
    });
}

export const getStore = async( endpoint ) => {
    let urls  = storeApi + endpoint;
    return await axios.get(urls,{ 
        headers: {
            Authorization: ls.get("token"),
            "Content-Type": "application/json"
        }
    });
}

export const post = async( endpoint,data ) => {
    let urls  = api + endpoint;
    return await axios.post(urls,data,{ 
        headers: {
            "Content-Type": "application/json"
        }
    });
}

// import axios from 'axios'
// import { api } from '../config/app'
// import { getCookie} from './session'

// export const get = async( endpoint ) => {
//     let urls  = api + endpoint;
//     return await axios.get(urls,{ 
//         headers: {
//             'Authorization': getCookie('token'),
//             "Content-Type": "application/json"
//         }
//     });
// }
