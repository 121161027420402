//library import
import React from 'react'
import {  Route, Switch } from "react-router";
//custom import
import GroceryMeat from '../groceryMeat/groceryMeatTable';
import notFound from './notFound';
import Rides from '../rides/ridesTable';
import DemandTable from '../demandDashboard/demandTable'
import Charts from '../chart/chart'
const Layout=()=>{
    return (
        <>
            <div className="col-12" style={{padding:"0px 5px"}}>
            <Switch>
                <Route exact path="/" component={DemandTable} />
                <Route path="/rides" component={Rides} />
                <Route path="/GroceryMeat" component={GroceryMeat} />
                <Route path="/chart" component={Charts} />
                <Route component={notFound}/>
            </Switch>
            </div>
        </>
    )
}

export default Layout
