//library import
import React,{useEffect} from 'react'
import queryString from 'query-string';
import ls from 'local-storage'
import { useLocation} from "react-router-dom";
import { withRouter } from 'react-router-dom';
//css import 
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/main.scss';
//custom import
// import Sidebar from './components/frame/sidebar';
// import Header from './components/frame/header';
import Layout from './components/frame/layout';
import { BACKGROUND_COLOR } from './lib/config';
import { getCookie, setCookie } from './lib/session';

const App = (props) => {
  const locationSearch = props.location.hash
  useEffect(()=>{
    if(!getCookie('token') || !ls.get("token"))
    {
      const search = locationSearch; // could be '?token="eykj.lkajdfs.lkasdjfl"'
      const parsed = queryString.parse(search);
      const token = parsed.token;
      const storeid = parsed["/?storeId"];
      const refToken = parsed.refToken;
      console.log(storeid,parsed,'fired')
      if (parsed && token && token.length) {
          ls.remove('store_id_demand_dashboard');
          ls.set('store_id_demand_dashboard', storeid);
          ls.remove('token');
          ls.set('token', token);
          ls.remove('refToken');
          ls.set('refreshToken', refToken);
          setCookie("token", token);
          setCookie("accessExpireAt", token);
          setCookie("refreshToken", refToken);
          console.log('Your history is going to push')
          props.history.push("/");
      }
    }
  },[])

  return (
        <>
          <style>
          {`
          .sidenav {
            height: 100vh;
            width:  210px;
            left: 0;
            overflow: none;
            background-color: ${BACKGROUND_COLOR};
            overflow-x: hidden;
            padding: 0px!important;
            z-index: 1;
            margin-top: 0px;
            position: fixed;
            /* min-height: 620px; */
          }
          .sidenav a {
            padding: 15px;
            text-decoration: none;
            font-size: 14px;
            text-align: left;
            color: #000000;
            display: block;
          }
          .main {
            // margin-left: 210px;
            margin-top: 5px;
            // overflow-y: auto;
            padding: 5px 10px 0px 10px;
          }          
          `}
          </style>
          <div className="container-fuild">
            <div className="row m-0">
              {/*<Header/>*/}
            </div>
            <div className="row mx-0">
              {/*<div className="sidenav col-6">
                <Sidebar/>
              </div>*/}
              <div className="col-12">
                <div className="main row">
                  <Layout/>
                </div>
              </div>
            </div>
          </div>
        </>
  );
}

export default withRouter(App);
