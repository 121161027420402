//library import
import React, { Component } from "react";
import Pagination from "react-js-pagination";
//css import  
import "./paginationJs.scss";

class PaginationJs extends Component {

    handlePageChange = (pageNumber) => {
        this.props.changeActivePage(pageNumber);
    }

    changeSize = (newVal) => {
        this.props.dataToShowHandler(newVal)
    }

    paginationSelector = (totalData) => {
        let pageGrup = [];
        for (let index = 20; index < totalData; index = index + 20) {
            if(index <= 100)
                pageGrup.push(<React.Fragment key={"aghaghajh-"+index}> <div className={`size ${ this.props.dataToShow === index ? 'selected' : ''}`} onClick={() => this.changeSize(index)}>{index}</div>{index === 100 ? null : <div className="divider">|</div>} </React.Fragment>)
        }
        return(
            <React.Fragment>
                {pageGrup.map((rowData,indxe) => rowData)}
            </React.Fragment> 
        )
    }

    render() {
        return (

            this.props.totalData > 20 ?
                <div className="paginationWrapper">
                    <div className="limitWrapper">
                        {this.paginationSelector(this.props.totalData)}
                    </div>
                    <div className="pageWrapper">
                    <Pagination
                        activePage={this.props.activePage}
                        itemsCountPerPage={this.props.dataToShow}
                        totalItemsCount={this.props.totalData}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                    />
                    </div>
                </div>
                : null
        );
    }
}

export default PaginationJs;