import React from 'react';

const NotExist = (props) => {
    const { count, height } = props
    return (
        <>
        <tr><td colSpan={count}></td></tr>
        <tr rowSpan="5" style={{height:height}} >
            <td colSpan={count} className="" style={{verticalAlign: "inherit",borderTop:"none"}}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <h6>Record does not exist.</h6>
                </div>
            </td>
        </tr>
        <tr><td colSpan={count} style={{border:"none"}}></td></tr>
        </>
    )
}

export default NotExist
