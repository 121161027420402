import cookie from "js-cookie";

// use all the below mentioned Utility Functions, to create, rmeove and manipulate Cookies. ( in whole project )
// Here, we are using "js-cookie" npm, to work with cookies.

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
      path: "/"
    });
  } else {
    console.log("setCookie failed");
  }
};

export const getCookie = (key, req) => {
  return process.browser ? getCookieFromBrowser(key) : "";
};
const getCookieFromBrowser = key => {
  return cookie.get(key);
};