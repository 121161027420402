import { get,getStore } from '../lib/request'
import ls from 'local-storage'

export const fetchStoreData = async ( search) => {
    let url = `/sla/store?search=${search}`;
    //  url = url + (search !== '' ? `?search=${search}` : "");
    return await getStore( url);
}

export const fetchDemand = async (skip = 0, limit = 0,selectDc,selectShift,startData,enddate,storeId,fcStoreID="") => {
    // let url = `/sla/demand/meat?storeId=${ls.get("store_id") || 0}&start_timestamp=${startData}&end_timestamp=${enddate}&skip=${skip}&limit=${limit}`;
console.log(fcStoreID,"idFC")
    let url = `/sla/grocery/dcDemand?storeId=${ls.get("store_id_demand_dashboard")}&timezone=Asia/Calcutta&start_time=${startData}&end_time=${enddate}&fcStoreId=${fcStoreID}&skip=${skip}&limit=${limit}`;
    if(selectDc.value !== "0" && selectDc.value !== 0 ){
        url = url + `&dc_id=${selectDc.value}`;
    }

    let arrayData =`&shift_id=[`;
    let flg = 0;
    if(selectShift.length !== 0){
        for(let key in selectShift){
            if(selectShift[key].value !== 0 && selectShift[key].value !== "0"){
                flg = 1;
                arrayData = arrayData + `"${selectShift[key].value}",`
            }
        }
    }
    if(flg === 1){
        arrayData = arrayData.substring(0, arrayData.length - 1);
        arrayData = arrayData + `]`;
        if(selectShift.findIndex((item) => item.value === "0") < 0){
            url = url + arrayData;
        }
    }
    return get(url);
};


export const fetchFcStores = () =>{

    return get("/sla/grocery/fcStores")

}

export const fetchDilogData = (productID,skip = 0, limit = 0,selectDc,selectShift,startData,enddate,storeId) => {
    
    let url = `/sla/demand/product?timezone=Asia/Calcutta&storeId=${ls.get("store_id") || 0}&product_id=${productID}&start_timestamp=${startData}&end_timestamp=${enddate}&skip=0&limit=100`;
    if(selectDc.value !== "0" && selectDc.value !== 0 ){
        url = url + `&dc_id=${selectDc.value}`;
    }

    let arrayData =`&shift_id=[`;
    let flg = 0;
    if(selectShift.length !== 0){
        for(let key in selectShift){
            if(selectShift[key].value !== 0 && selectShift[key].value !== "0"){
                flg = 1;
                arrayData = arrayData + `"${selectShift[key].value}",`
            }
        }
    }
    if(flg === 1){
        arrayData = arrayData.substring(0, arrayData.length - 1);
        arrayData = arrayData + `]`;
        url = url + arrayData;
    }
    return get(url);
};

export const exportDatafetch = (productID,skip = 0, limit = 0,selectDc,selectShift,startData,enddate,storeId) => {
    
    let url = `/sla/demand/product?storeId=${ls.get("store_id") || 0}&product_id=${productID}&start_timestamp=${startData}&end_timestamp=${enddate}&skip=0&limit=100`;
    if(selectDc.value !== "0" && selectDc.value !== 0 ){
        url = url + `&dc_id=${selectDc.value}`;
    }

    let arrayData =`&shift_id=[`;
    let flg = 0;
    if(selectShift.length !== 0){
        for(let key in selectShift){
            if(selectShift[key].value !== 0 && selectShift[key].value !== "0"){
                flg = 1;
                arrayData = arrayData + `"${selectShift[key].value}",`
            }
        }
    }
    if(flg === 1){
        arrayData = arrayData.substring(0, arrayData.length - 1);
        arrayData = arrayData + `]`;
        url = url + arrayData;
    }
    return get(url);
};

export const fecthDownloadData = (skip = 0, limit = 0,selectDc,selectShift,startData,enddate,storeId, exportCount,fcId="") => {
    // let url = `/sla/demand/meat?storeId=${ls.get("store_id") || 0}&start_timestamp=${startData}&end_timestamp=${enddate}&skip=${skip}&limit=${limit}&export=${exportCount}`;

    let url =`/sla/grocery/dcDemand?storeId=${ls.get("store_id") }&timezone=Asia/Calcutta&start_time=${startData}&end_time=${enddate}&fcStoreId=${fcId}&skip=${skip}&limit=${limit}`
    
    if(selectDc.value !== "0" && selectDc.value !== 0 ){
        url = url + `&dc_id=${selectDc.value}`;
    }

    let arrayData =`&shift_id=[`;
    let flg = 0;
    if(selectShift.length !== 0){
        for(let key in selectShift){
            if(selectShift[key].value !== 0 && selectShift[key].value !== "0"){
                flg = 1;
                arrayData = arrayData + `"${selectShift[key].value}",`
            }
        }
    }
    if(flg === 1){
        arrayData = arrayData.substring(0, arrayData.length - 1);
        arrayData = arrayData + `]`;
        url = url + arrayData;
    }
    return get(url);
}