import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink } from "react-csv";

export default function ResponsiveDialog(props) {
  console.log("data=-------------",props.data);
  return (
      <>
    <style>
    {`
        .btn-dailog:focus{
            outline:none!important;
        }
    `}
    </style>
    <div>
      <Dialog
        open={props.openState}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Export your Table Data"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Table will be exported as a CSV (comma separated values).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} className="btn-dailog text-primary">
            Cancel
          </Button>
          <Button onClick={props.handleClose} className="btn-dailog text-primary">
            <CSVLink data={props.data} filename={"demand_dashboard.csv"}  >Ok</CSVLink>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
}
