//library import
import React from 'react'
import Select from 'react-select';
import {connect} from 'react-redux'
//css import  
import "./reactSelect.scss";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: "0.8rem",
        cursor: 'pointer',
        // zIndex: '7 !important',
    }),
    control: (base, state) => ({
        ...base,
         width: 200,
         height:30,
        overflow: "auto!important",
        border: state.isFocused ? "1px solid #bbb" : "1px solid #bbb",
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
            border: state.isFocused ? "1px solid black" : "1px solid black"
        }
    })
    
}

const mapStateToProps = (state) => {
    return {
      abc : state
    }
  };  

export default connect (mapStateToProps)((props) => {
    let handleChange = selectedOption => {
        props.change(selectedOption)
    };

    return (
        <div>
            <Select
                value={props.selected || {}}
                isMulti={props.isMulti || false}
                name={props.name}
                options={props.data}
                className={"basic-multi-select "+ (props.class || "") }
                classNamePrefix="select"
                styles={customStyles}
                onChange={handleChange}
                placeholder={props.placeholder || " "}
                isSearchable={props.isSearchable || false}
            />
        </div>

    )

});
