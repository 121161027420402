//library import
import React from 'react'
import { DatePicker } from 'antd';
import moment from 'moment';
//custom import
import { dateFilter } from "../../feature/dateFilter/dateFIlter";
//css import  
import 'antd/dist/antd.css';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
// const monthFormat = 'YYYY/MM';
// const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
// const customFormat = value => `custom format: ${value.format(dateFormat)}`;

const GlobalFilter = (props) => {

    // const intDate = {
    //     startdate: moment()
    //       .startOf("day")
    //       .format("YYYY-MM-DD HH:mm:ss"),
    //     endDate: moment()
    //       .endOf("day")
    //       .format("YYYY-MM-DD HH:mm:ss")
    //   };
  
    //   const handleRange = (...arges) => {
    //     let startDate =
    //       arges[0].length !== 0
    //         ? arges[0][0].startOf("day").format("YYYY-MM-DD HH:mm:ss")
    //         : intDate.startdate;
    //     let endDate =
    //       arges[0].length !== 0
    //         ? arges[0][1].endOf("day").format("YYYY-MM-DD HH:mm:ss")
    //         : intDate.endDate;
    //     if (handleRange) {
    //       handleRange(startDate, endDate);
    //     }
    //   };

      const onChange = (value, dateString) => {
        props.onfilterChange(moment(dateString[0]).format("X"),moment(dateString[1]).endOf("day").format("X"))
      }
    return (
        <>
            <style>
          {`
             .search >
              div::before{
                    z-index: 555;
                    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
                }
                div::after{
                    z-index: 555;
                    border-bottom: 2px solid #E5E8ED;
                }
            .datepiker{
                width:100%;
            }
            .datepiker div{
              height: 38px;
            }
            .ant-picker-range-separator {
              line-height: 2!important;
            }
          `}
          </style>
            <div className="datepiker">
              { /*<input className="form-control" value={filter || ''} onChange={e => setFilter(e.target.value)}/>*/ }
              <RangePicker
              ranges={{
                ...dateFilter
              }}
                defaultValue={[
                  moment(moment().startOf("day")),
                  moment(moment().endOf("day"))
                ]}
                format={dateFormat}
                onChange={onChange}
                disabledDate={current => {
                  return current && current > moment().add(7, "days");
                }
              }
              />
            </div>
        </>
    )
}

export default GlobalFilter
