//library import
import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import NotExist from "./notExist";
import GetAppIcon from "@material-ui/icons/GetApp";
//custom import
import GlobalFilter from "./globalFilter";
import Loader from "../loader/Loader";
//css import
import "font-awesome/css/font-awesome.min.css";
import "./reactTable.scss";
import ReactSelect from "../reactSelect/reactSelect";
import DescriptionIcon from "@material-ui/icons/Description";
import moment from "moment";
import { fetchDemand, fetchFcStores } from "../../services/demandDashboard";

export default function ReactTable(props) {
  const { columns, data, columCounts, name, loaders, classes } = props; //dataToShow
  const [allFc, setAllFc] = useState([]);
  const [selectedSort, setSelectedSort] = useState({
    label: "Select",
    value: "",
  });
  // React.useEffect(()=>{
  //   ref(this)
  // })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn
      initialState: {
        pageSize: 100, // show 2 record pr page
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter } = state;
  // <div className="col-auto">
  //   <ReactSelect class="customeSelect" isMulti={true} style={{ width: "10px" }} data={props.shiftData || []} selected={props.shiftDataSelected || []} change={props.changeShiftHandler} />
  // </div>

  function SwitchCase({ value }) {
    switch (value) {
      case "Demand":
        return (
          <React.Fragment>
            <div>
              <div className="form-row justify-content-center align-items-center">
                <div className="col-auto">
                  <ReactSelect
                    data={props.dcData || []}
                    selected={props.dcDataSelected || []}
                    change={props.dcChangeDcHandler}
                  />
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-outline-dark link_button"
                    onClick={props.handleClickOpen}
                  >
                    {props.shiftDataSelected.length === props.shiftData.length
                      ? props.shiftDataSelected.length - 1
                      : props.shiftDataSelected.length}{" "}
                    Shift Selected
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "Variant Specification":
        return (
          <React.Fragment>
            <div>
              <div className="form-row justify-content-center align-items-center">
                <div className="col-auto">
                  <ReactSelect
                    data={props.varient || []}
                    selected={props.selectedVarient || []}
                    change={props.dcChangeDcHandler}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return "No Options Here";
    }
  }

  const sortSelectHandler = (option) => {
    setSelectedSort(option)
 
  };

  useEffect(()=>{
    props.handleFC(selectedSort.value)
  },[selectedSort])

  console.log(props.demandData, "demandData");

  useEffect(()=>{
    fetchFcStores().then((res)=>{
      console.log(res,"FCSTORES")

      const remappedVariables = res.data.data.map((elem)=>({
        label:elem.storeName,
        value:elem.storeId
      }))

      setAllFc([{
        label:"All",
        value:""
      },...remappedVariables])

    })
  },[])

  return (
    <React.Fragment>
      <div     ref={(el) => {
          if (props.reff) props.reff(el);
        }}>
      <div className="table_title">
        <div className="row align-items-center">
          <div className="col-8">
            <div className="form-row justify-content-left align-items-center">
              <div className="col-auto">
                <h5 className="mb-0">{name}</h5>
              </div>
              {props.storeSelect && (
                <div className="col-auto">{props.storeSelect}</div>
              )}
            </div>
          </div>
          {/* --- Search Filter --- */}

          <div className="col-4 d-flex justify-content-end">
            <div className="d-flex mr-3 align-items-center ">
              {props.dataPrint}
            </div>
            <div className="d-flex mr-3 align-items-center ">
              <GetAppIcon onClick={props.exportfile} className="file_exports" />
            </div>
          
          </div>
        </div>
      </div>

      <div className="fcfilter d-flex" >
        <span
          className="mr-2 d-flex align-items-center"
          // style={{ marginLeft: "20px" }}
        >
          FC Stores:
        </span>




        <div className="mr-2 globalFontSize">
          <ReactSelect
            data={allFc || []}
            change={sortSelectHandler}
            selected={selectedSort || {}}
          />
        </div>

        <span
          className="mr-2 d-flex align-items-center"
          // style={{ marginLeft: "20px" }}
        >
          Date Range:
        </span>
        <div>
              <GlobalFilter
                onfilterChange={props.onFilterChnage}
                filter={globalFilter}
                setFilter={setGlobalFilter}
              />
            </div>
      </div>

      <div
        className="table-responsive table contents"
        style={{ overflowX: "inherit" }}
    
      >
        {props.demandData && props.demandData.length ? (
          <div className="backColor">
            <table
              {...getTableProps()}
              className={`table table-striped ${classes} box-shadow `}
              style={{ marginTop: "35px" }}
            >
              <thead>
                {headerGroups.map((headerGroup, inde) => (
                  <tr
                    key={"aaaaaaaaaa1" + inde}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <React.Fragment key={"aaaaaaaaaa1" + inde + index}>
                          {column.Header != "Demand" && (
                            <th>
                              {/* <div className="bg-grey"> */}
                              <div
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {console.log(column, "checkkkk")}{" "}
                                <span
                                  className="mb-2"
                                  style={{ fontSize: "17px" }}
                                >
                                  {column.render("Header")}
                                </span>
                                {/* <span className="ml-2"> 
                              {column.isSorted ? (column.isSortedDesc ? '↑' : '↓') : ''}
                            </span> */}
                              </div>
                              {/* <div >{ column.canFilter && column.Header ?
                            <SwitchCase value={column.Header} />
                            : null}
                          </div> */}
                              {/* </div> */}
                            </th>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {loaders ? (
                  <tr rowSpan="5" style={{ height: "0px" }}>
                    <td
                      colSpan={columCounts}
                      className=""
                      style={{ verticalAlign: "inherit", fontSize: "19px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {
                          //conditional rendering of loader
                          <Loader />
                        }
                      </div>
                    </td>
                  </tr>
                ) : (
                  //  :
                  //   page.length !== 0 ?
                  //   page.map((row, indxe) => {
                  //     prepareRow(row)
                  //     return (
                  //       <tr key={"roaaawsss-" + indxe} {...row.getRowProps()}>
                  //         {row.cells.map((cell, ind) => {
                  //           return <td keys={"cell-" + ind + indxe} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  //         })}
                  //       </tr>
                  //     )
                  //   }) : <NotExist count={columCounts} height="256px" />
                  props.demandData &&
                  props.demandData.length &&
                  props.demandData.map((elem) => {
                    return (
                      <tr>
                        <td style={{ fontSize: "15px" }}>{elem["S. No."]}</td>
                        <td style={{ fontSize: "15px" }}>
                          {elem["From Store"]}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {elem["Product Name"]}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {elem["Order Date"] }
                        </td>
                        <td style={{ fontSize: "15px" }}>{elem["SKU"]}</td>
                        <td style={{ fontSize: "15px" }}>
                          {elem["Variant Specification"]}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {elem["Total Demand"]}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>{" "}
          </div>
        ) : (
          <div className="backColor noDataDiv">
            <div className="tableHeaderContent">
              <div className="tableLableDiv">
                <div className="tableIcon">
                  <DescriptionIcon></DescriptionIcon>
                </div>
              </div>

              <p>Record does not exist.</p>
            </div>
          </div>
        )}
      </div>
      </div>
    </React.Fragment>
  );
}
