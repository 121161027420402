//library import
import  moment  from 'moment';
//custom import
import  { replaceBlankWithDash } from '../../lib/helper'

export const Columns = [
    {
        Header: 'City',
        accessor:  data => replaceBlankWithDash(data.pickupAddress.city),
        disableFilters: true
      },
      {
        Header: 'Store Name',
        accessor:  data => replaceBlankWithDash(data.storeName),
        disableFilters: true
      },
      {
        Header: 'DC / Store',
        accessor: data => replaceBlankWithDash(data.vehicleDetails.vehicleYear) ,//pending
        disableFilters: true
      },
      {
        Header: 'Date',
        accessor:  data => replaceBlankWithDash(data.slotDetails.date),
        disableFilters: true,
        minWidth: 300
      },
      {
        Header: 'Delivery Slot',
        accessor:  data => replaceBlankWithDash(data.slotDetails.shiftName),
        disableFilters: true
        // Cell:({value})=>{return format(new Date(value),'HH')}
      },
      {
        Header: 'Order Id',
        accessor:  data => replaceBlankWithDash(data.masterOrderId),
        disableFilters: true
      },
      {
        Header: 'Order Accepted',
        accessor:  data => replaceBlankWithDash(data.orderAccepted),
        disableFilters: true
      },
      {
        Header: 'Picker Assigned',
        accessor:  data => replaceBlankWithDash(data.pickerAssigned),
        disableFilters: true
      },
      {
        Header: 'Picking Completed',
        accessor:  data => replaceBlankWithDash(data.pickingCompleted),
        disableFilters: true
      },
      {
        Header: 'Driver Assigned',
        accessor:  data => replaceBlankWithDash(data.DriverAssigned),
        disableFilters: true
      },
      {
        Header: 'checkoutN Picking Completed',
        accessor:  data => replaceBlankWithDash(data.checkoutNPickingCompleted),
        disableFilters: true,
        Cell : ({ value }) => { 
          return (typeof value === "number" ?
                      moment(new Date(value*1000).toISOString()).format("DD MMM YYYY hh:mm:ss A")
                      :  value)
          },
      },
      {
        Header: 'Order PickedUp',
        accessor:  data => replaceBlankWithDash(data.OrderPickedUp),
        disableFilters: true,
        Cell : ({ value }) => { 
          return (typeof value === "number" ?
                      moment(new Date(value*1000).toISOString()).format("DD MMM YYYY hh:mm:ss A")
                      :  value)
          },
      },
      {
        Header: 'At Drop Location',
        accessor:  data => replaceBlankWithDash(data.AtDropLocation),
        disableFilters: true
      },
      {
        Header: 'Delivery Complete',
        accessor:  data => replaceBlankWithDash(data.DeliveryComplete), //pending
        disableFilters: true
      },
      {
        Header: 'The Batch',
        accessor:  data => replaceBlankWithDash(data.vehicleDetails.vehicleId), //pending
        disableFilters: true
        // Cell:({value})=>{return format(new Date(value),'dd/MM/yyyy')}
      },
      ]
  